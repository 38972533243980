import React, { memo, useState, useContext } from 'react'
import { List, Label, Button, Icon, Segment, Modal } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import http from 'services/http'
import { map } from 'rxjs/operators'
import { toast } from 'react-toastify'
import produce from 'immer'
import AnimatedModal from 'components/AnimatedModal'
import EditDocForm from './EditDocForm'
import { GlobalContext } from 'globalContext'

export default memo(function DocumentsResult({ docs, setDocuments }) {

    const [globalState] = useContext(GlobalContext)
    const [isLoading, setIsLoading] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [docToEdit, setDocToEdit] = useState(null)
    const [docToRemove, setDocToRemove] = useState(null)
    const getDocumentLink$ = id => http.request({ url: `/documents/get-link/${id}`, method: 'GET' }).pipe( map( ({ response }) => response ) )
    const deleteDocument$ = id => http.request({ url: `/documents/${id}`, method: 'DELETE' }).pipe( map( ({ response }) => response ) )

    function open(_, { doc }) {
        setIsLoading(true)
        getDocumentLink$(doc).subscribe(response => {
            setIsLoading(false)
            if (!response || response.error) 
                toast.error(response ? response.message : 'Ops, houve um erro :(')
            else {
                if (!response.link)
                    toast.info('Não foi possível encontrar o anexo.')
                else
                    window.open(response.link, '_blank')
            }
        })
    }

    function openEditForm(doc) {
        setDocToEdit(doc)
        setOpenEdit(true)
    }

    function openRemoveModal(doc) {
        setDocToRemove(doc)
        setOpenDelete(true)
    }

    function closeEditForm(doc) {
        const documents = produce(docs, draft => {
            const idx = draft.findIndex(x => x._id === doc._id)
            draft[idx] = doc
        })
        setDocuments(documents)
        setTimeout(() => setOpenEdit(false), 1500)        
    }

    function closeDeleteModal() {
        const documents = produce(docs, draft => {
            const idx = draft.findIndex(x => x._id === docToRemove._id)
            draft.splice(idx, 0)
        })
        setDocuments(documents)
        setOpenDelete(false)
    }

    function doDeleteDocument() {
        setIsLoading(true)
        deleteDocument$(docToRemove._id).subscribe(response => {
            setIsLoading(false)
            if (!response || response.error)
                toast.error(response ? response.message : 'Ops, houve um erro :(')
            else {
                toast.success('Documento deletado com sucesso!')
            }
            closeDeleteModal()
        })
    }

    return (
        <div className='mt-5'>
            {docs.length === 0 && <Segment className='text-center' content='Sem resultados'/>}
            <List divided relaxed size='medium' animated>
                {docs.map((doc, idx) => 
                    <List.Item key={idx}>
                        <List.Icon name='file alternate outline' size='huge' verticalAlign='middle' />
                        <List.Content>
                            <List.Header as='span'>
                                <span className='pr-2' style={{ color: '#3480eb' }}>{doc.titulo}</span>
                                {doc.tags.map((tag, tIdx) => <Label className='mx-2' size='mini' key={tIdx} tag content={tag.name} color='green' />)} 
                            </List.Header>
                            <List.Description className='py-1'>{doc.descricao}</List.Description>
                            <List.Description className='pb-1'>
                                <strong>Criado em:</strong> <span className='pr-2'>{DateTime.fromISO(doc.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')}</span> 
                                <strong>Última atualização:</strong> {doc.updatedAt ? DateTime.fromISO(doc.updatedAt).toFormat('dd/MM/yyyy HH:mm:ss') : ' --- '}
                            </List.Description>
                            <List.Description className='pb-1'>
                                <Button.Group size='mini'>
                                    <Button animated='fade' doc={doc._id} onClick={open} loading={isLoading} disabled={isLoading}>
                                        <Button.Content hidden>Abrir</Button.Content>
                                        <Button.Content visible><Icon name='external alternate' /></Button.Content>
                                    </Button>
                                    <Button animated='fade' onClick={() => openEditForm(doc)} disabled={globalState.user && !globalState.user.canUpload}>
                                        <Button.Content hidden>Editar</Button.Content>
                                        <Button.Content visible><Icon name='edit outline' /></Button.Content>
                                    </Button>
                                    <Button animated='fade' onClick={() => openRemoveModal(doc)} disabled={globalState.user && !globalState.user.canUpload}>
                                        <Button.Content hidden>Excluir</Button.Content>
                                        <Button.Content visible><Icon name='trash alternate outline' /></Button.Content>
                                    </Button>
                                </Button.Group>
                            </List.Description>
                        </List.Content>
                    </List.Item>    
                )}
            </List>
            <AnimatedModal open={openEdit}>
                <Modal open={true} dimmer='blurring' size='large' closeIcon onClose={() => setOpenEdit(false)}>
                    <Modal.Header>Editar documento</Modal.Header>
                    <Modal.Content>
                        <EditDocForm doc={docToEdit} closeEditForm={closeEditForm} />
                    </Modal.Content>
                </Modal>
            </AnimatedModal>
            <AnimatedModal open={openDelete}>
                <Modal open={true} dimmer='blurring' size='tiny' basic onClose={() => setOpenDelete(false)}>
                    <Modal.Header>Excluir documento</Modal.Header>
                    <Modal.Content>
                        {docToRemove && <p>Gostaria realmente de excluir "{docToRemove.titulo}" ?</p>}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' inverted onClick={() => setOpenDelete(false)} disabled={isLoading}>
                            <Icon name='remove' /> Não
                        </Button>
                        <Button color='green' inverted onClick={doDeleteDocument} loading={isLoading} disabled={isLoading}>
                            <Icon name='checkmark' /> Sim
                        </Button>
                    </Modal.Actions>
                </Modal>
            </AnimatedModal>
        </div>
    )
})
