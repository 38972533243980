import React from 'react'
import { TransitionablePortal } from 'semantic-ui-react'

export default function AnimatedModal(props) {

    return (
        <TransitionablePortal open={props.open} transition={{ animation: 'scale', duration: 400 }} onOpen={() => setTimeout(() => document.body.classList.add('modal-fade-in'), 0)} onClose={() => setTimeout(() => document.body.classList.remove('modal-fade-in'), 0)}>
            {props.children}
        </TransitionablePortal>
    )
}
