import React, { useContext, useState } from 'react'
import { Form, Button, Icon } from 'semantic-ui-react'
import { GlobalContext } from 'globalContext'
import http from 'services/http'
import { map } from 'rxjs/operators'
import { toast } from 'react-toastify'
import produce from 'immer'

export default function TagsForm() {

    const [globalState, dispatchGlobal] = useContext(GlobalContext)
    const [isLoading, setIsLoading] = useState(false)
    const [value, setValue] = useState('')
    const [selected, setSelected] = useState(null)

    const submit$ = () => http.request({ url: `/tags/${selected}`, method: 'PATCH', body: { name: value } }).pipe( map(({ response }) => response) )

    function handleSubmit() {
        if (!(!!value) || !(!!selected))
            toast.error('Por favor, preencha o formulário antes de enviar.')
        else {
            setIsLoading(true)
            submit$().subscribe(response => {
                setIsLoading(false)
                if (!response || response.error)
                    toast.error(response ? response.message : 'Ops, houve um erro :(')
                else {
                    toast.success('Tag atualizada com sucesso!')
                    const tags = produce(globalState.tags, draft => {
                        const idx = draft.findIndex(x => x && x._id === response.tag._id)
                        draft[idx] = response.tag
                    })
                    dispatchGlobal({ type: 'setTags', payload: tags })
                    setValue('')
                    setSelected(null)
                }
            })
        }
    }

    return (
        <Form size='small' onSubmit={handleSubmit} noValidate>
            <div className="row">
                <div className="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                    <Form.Select label='Escolha uma tag' required onChange={(_, { value }) => [setValue(globalState.tags.find(x => x && x._id === value).name), setSelected(value)]} options={globalState.tags ? globalState.tags.map(x => Object.assign({}, { text: x.name, value: x._id })) : []} />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-6 offset-md-3  col-lg-4 offset-lg-4">
                    <Form.Input label='Nome' required placeholder='Novo nome para tag' value={value} onChange={(_, { value }) => setValue(value)} />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                    <Button animated='fade' color='green' loading={isLoading} disabled={isLoading}>
                        <Button.Content visible>Enviar</Button.Content>
                        <Button.Content hidden><Icon name='send' /></Button.Content>
                    </Button>
                </div>
            </div>
        </Form>
    )
}
