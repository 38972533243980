import React, { useReducer, useState, useContext } from 'react'
import { GlobalContext } from 'globalContext'
import { Form, Button, Icon, Message } from 'semantic-ui-react'
import http from 'services/http'
import { map } from 'rxjs/operators'

function reducer(state, action) {
    switch (action.type) {
        case 'setForm':
            return { ...state, ...action.payload }
        default:
            return state
    }
}

export default function EditDocForm({ doc, closeEditForm }) {

    const [globalState] = useContext(GlobalContext)
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState({visible: false})
    const [state, dispatch] = useReducer(reducer, {
        titulo: doc.titulo,
        descricao: doc.descricao,
        arquivo: '',
        tags: [].concat(doc.tags.map(x => x.name))
    })

    const submit$ = form => http.request({ url: `/documents/${doc._id}`, method: 'PATCH', body: form }).pipe( map(({ response }) => response) )

    function handleChange(event, { name, value }) {
        if (name === 'arquivo')
            value = event.target.files[0]
        dispatch({ type: 'setForm', payload: { [name]: value } })
    }

    function handleSubmit() {
        if (!(!!state.titulo) || !(!!state.descricao) || !(!!state.tags))
            setMessage({ visible: true, content: 'Por favor, preencha o formulário antes de enviar!', error: true })
        else {
            const formData = new FormData()
            for (const key of Object.keys(state)) {
                if (key === 'arquivo' && state[key] !== '')
                    formData.append(key, state[key], state[key].name)
                else if (Array.isArray(state[key])) {
                    for (const value of state[key]) {
                        formData.append(`${key}[]`, value)
                    }
                } else
                    formData.append(key, state[key])
            }
            setIsLoading(true)
            submit$(formData).subscribe(response => {
                setIsLoading(false)
                if (!response || response.error) {
                    if (response && 'message' in response)
                        setMessage({ visible: true, content: response.message, error: true })
                    else
                        setMessage({ visible: true, content: 'Ops, ocorreu um erro :(', error: true })
                } else {
                    setMessage({ visible: true, content: 'Documento atualizado com sucesso!', success: true })
                    closeEditForm(response.document)
                }
            })
        }
    }

    return (
        <Form onSubmit={handleSubmit} noValidate>
            {message.visible && <Message {...message} />}
            <div className="row pt-3">
                <div className="col-12 col-md-4 offset-md-1 col-lg-3 offset-lg-2 pt-1">
                    <Form.Input required label='Titulo' placeholder='Informe um titulo' name='titulo' value={state.titulo} onChange={handleChange} autoComplete='off' />
                </div>
                <div className="col-12 col-md-6 col-lg-5 pt-1">
                    <Form.Input required label='Descrição' placeholder='Informe uma descrição' name='descricao' value={state.descricao} onChange={handleChange} autoComplete='off' />
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-12 col-md-4 offset-md-1 col-lg-4 offset-lg-2 pt-1">
                    <Form.Input type='file' accept='.pdf,.odt,.odf,.ods,.doc,.docx,.xls,.xlsx' label='Arquivo' name='arquivo' onChange={handleChange} />
                </div>
                <div className="col-12 col-md-6 col-lg-4 pt-1">
                    <Form.Select required multiple search noResultsMessage='Nenhum resultado' label='Tags' placeholder='Adicionar tags' name='tags' value={state.tags} onChange={handleChange} options={globalState.tags ? globalState.tags.map((x, i) => Object.assign({}, { key: i, text: x.name, value: x.name })) : []} />
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-12 col-md-4 offset-md-1 col-lg-3 offset-lg-2">
                    <Button animated='fade' color='green' loading={isLoading} disabled={isLoading}>
                        <Button.Content visible>Enviar</Button.Content>
                        <Button.Content hidden><Icon name='send' /></Button.Content>
                    </Button>
                </div>
            </div>
        </Form>
    )
}
