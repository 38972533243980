import React, { useEffect, useCallback, useContext } from 'react'
import { withRouter, Link, Redirect} from 'react-router-dom'
import http from 'services/http'
import { of, timer } from 'rxjs'
import { switchMap, take, map, tap, filter } from 'rxjs/operators'
import { Menu, Icon, Dimmer, Loader } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { AutoSizer } from 'react-virtualized'
import { Scrollbars } from 'react-custom-scrollbars'
import { GlobalContext } from '../globalContext'
import jwt from 'jsonwebtoken'
import 'react-toastify/dist/ReactToastify.min.css'
import './Template.scss'

toast.configure({
    autoClose: 5000,
    draggable: false,
    position: 'bottom-right'
})

function Template(props) {

    const { location } = props
    const [stateGlobal, dispatchGlobal] = useContext(GlobalContext)
    const getTags$ = http.request({ url: '/common/tags', method: 'GET' }).pipe( 
        map(({ response }) => {
            if (!response || response.error)
                return []
            return response.tags
        }) 
    )
    const makeAuthRequest = useCallback(config => http.request(config).pipe( 
        map(({ response }) => {
            if (!response || response.error)
                return false
            localStorage.setItem('JWTBV', response.token)
            return jwt.decode(response.token)
        }) 
    ), [])
    const checkAuthentication = useCallback(() => { 
        try {
            let token = localStorage.getItem('JWTBV'), cookie = decodeURIComponent(document.cookie).split("dpnet=")[1]
            if (!cookie)
                return of(false)
            else if (cookie && token) {
                token = jwt.decode(token)
                if (cookie !== token.issuedFor) {
                    return makeAuthRequest({ url: '/authenticate', method: 'POST', body: { cookie } })
                } else {
                    const now = Date.now() / 1000
                    if (now > token.exp) 
                        return makeAuthRequest({ url: '/authenticate', method: 'POST', body: { cookie } })
                    else 
                        return of(token)
                }
            } else if (cookie && !token) {
                return makeAuthRequest({ url: '/authenticate', method: 'POST', body: { cookie } })
            }
        } catch (error) {
            return of(false)
        }
    }, [makeAuthRequest])

    useEffect(() => {
        let sub
        if (!!stateGlobal.user === false) {
            sub = timer(0, 1000).pipe( 
                switchMap( () => checkAuthentication() ),
                tap(data => {
                    if (!!data === false)
                        dispatchGlobal({ type: 'isCheckingAuth', payload: false })
                }), 
                filter(x => !!x !== false),
                take(1) 
            ).subscribe(user => dispatchGlobal({ type: 'setUser', payload: user }))        
        }
        if (stateGlobal.tags === null)
            getTags$.subscribe(tags => dispatchGlobal({ type: 'setTags', payload: tags }))

        return () => {
            if (sub)
                sub.unsubscribe()
        }

    }, [getTags$, stateGlobal.user, stateGlobal.tags, checkAuthentication, dispatchGlobal])

    if (!stateGlobal.isCheckingAuth) {
        if (!stateGlobal.user && location.pathname !== '/sessao-expirada')
            return <Redirect to='/sessao-expirada' />
        else if ( stateGlobal.user && location.pathname === '/sessao-expirada')
            return <Redirect to='/' />
    }

    return (
        <div className='container-fluid px-0' id="template">
            <AutoSizer>
                {({ width, height }) => (
                    <div style={{ width, height }}>
                        <Dimmer active={stateGlobal.isCheckingAuth} inverted>
                            <Loader content='CHECANDO AUTENTICAÇÃO' />
                        </Dimmer>
                        <div className='wrapper'>
                            <Menu className='system-menu'>
                                <Menu.Item as="span"><Link to='/'><Icon name='home' /> HOME</Link></Menu.Item>
                                <Menu.Item as="span"><Link to='/acervo'><Icon name='cloud' /> ACERVO</Link></Menu.Item>
                                {!!stateGlobal.user && <Menu.Item className='user' as="span" position="right" >
                                    <Icon name="user" /> {stateGlobal.user.nome}
                                </Menu.Item>}
                            </Menu>
                            <div className="content">
                                <AutoSizer>
                                    {({ width: innerWidth, height: innerHeight }) => 
                                        <Scrollbars style={{ width: innerWidth, height: innerHeight }}>
                                            {props.children}
                                        </Scrollbars>
                                    }
                                </AutoSizer>
                            </div>
                        </div>
                    </div>
                )}
            </AutoSizer>
        </div>
    )
}

export default withRouter(Template)
