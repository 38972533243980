import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { GlobalContextProvider } from "./globalContext";
import history from "./history";
import Routes from "./Routes";
import * as serviceWorker from "./serviceWorker";
import "assets/scss/app.scss";

ReactDOM.render(
  <GlobalContextProvider>
    <Router basename="/" history={history}>
      <Routes />
    </Router>
  </GlobalContextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
