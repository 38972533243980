import { ajax } from 'rxjs/ajax'
import { catchError } from 'rxjs/operators'
import { of } from 'rxjs'
import history from '../history.js'

export default {
    request: function (config = {}) {
        const defaultRequest = {
            url: `${process.env.REACT_APP_API}`,
            headers: {
                Authorization: `JWT ${localStorage.getItem('JWTBV')}`
            }
        }
        config = Object.assign({}, defaultRequest, config)
        config.url = `${defaultRequest.url}${config.url ? config.url : '/'}`
        config.headers = config.headers ? Object.assign({}, config.headers, defaultRequest.headers) : defaultRequest.headers
        return ajax(config).pipe(
            catchError(error => {
                if (error.status) {
                    if (error.status === 401 && !/sessao-expirada/.test(window.location.href))
                        history.push('/sessao-expirada')
                }
                return of(error)
            })
        )
    }
}

