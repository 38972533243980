import React, { useState, useRef, useContext, memo } from 'react'
import { fromEvent, of } from 'rxjs'
import { Form, Ref, Button } from 'semantic-ui-react'
import { debounceTime, map, distinctUntilChanged, tap } from 'rxjs/operators'
import { useObservable } from 'utils'
import { GlobalContext } from 'globalContext'
import http from 'services/http'
import { toast } from 'react-toastify';

export default memo(function SearchForm({ setDocuments }) {

    const [globalState] = useContext(GlobalContext)
    const [isLoading, setIsLoading] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [tags, setTags] = useState([])
    const [text, setText] = useState("")
    const searchInput = useRef(null)
    const form = useRef(null)

    const input$ = searchInput.current ? fromEvent(searchInput.current, 'keyup').pipe(
        debounceTime(350),
        map(event => event.target.value),
        distinctUntilChanged(),
        tap(setText),
        tap(searchDocuments)
    ) : of(null)

    const request$ = body => http.request({ url: '/documents/search', method: 'POST', body }).pipe( map( ({ response }) => response ) )

    function searchDocuments(value, values = tags) {
        setIsLoading(true)
        request$({ text: value, tags: values }).subscribe(response => {
            setIsLoading(false)
            if (!response || response.error)
                toast.error(response ? response.message : 'Ops, houve um erro :(')
            else 
                setDocuments(response.documents)
        })
    }

    function exibirTudo() {
        setIsFetching(true)
        setText("")        
        setTags([])
        request$({ showAll: true }).subscribe(response => {
            setIsFetching(false)
            form.current.reset()
            if (!response || response.error)
                toast.error(response ? response.message : 'Ops, houve um erro :(')
            else 
                setDocuments(response.documents)
        })
    } 

    function handleChange(_, { value }) {
        setTags(value)
        searchDocuments(text, value)
    }

    useObservable(input$)

    return (
        <Ref innerRef={form}>
            <Form size='small' noValidate>
                <div className="row">
                    <div className="col-12 col-lg-4 offset-lg-1 pt-2">
                        <Ref innerRef={searchInput}><Form.Input disabled={isFetching} placeholder='Pesquisar documentos...' icon='search' name='search' autoComplete='off' loading={isLoading} /></Ref>
                    </div>
                    <div className="col-12 col-lg-4 pt-2">
                        <Form.Select multiple placeholder='Pesquisar tags' disabled={isFetching} name='tags' onChange={handleChange} options={globalState.tags ? globalState.tags.map((x, i) => Object.assign({}, { key: i, text: x.name, value: x._id })) : []} />
                    </div>
                    <div className='col-12 col-lg-2 pt-2'>
                        <Button color='green' loading={isFetching} inverted onClick={exibirTudo}>Exibir tudo</Button>
                    </div>
                </div>
            </Form>
        </Ref>
    )
})
