import React, { useState, useContext } from 'react'
import { Menu, Segment, Icon } from 'semantic-ui-react'
import UploadForm from './UploadForm'
import SearchForm from './SearchForm'
import TagsForm from './TagsForm'
import DocumentsResult from './DocumentsResult'
import { GlobalContext } from 'globalContext'

export default function AcervoPage() {

    const [globalState] = useContext(GlobalContext)
    const [active, setActive] = useState('arquivos')
    const [documents, setDocuments] = useState([])

    function handleTabClick(event, { name }) {
        if (name === 'upload' && !globalState.user.canUpload)
            event.preventDefault()
        else
            setActive(name)
    }

    return (
        <div className='container page-content'>

            <div className="row">
                <div className="col">

                    <Menu tabular attached='top' size='mini' borderless>
                        <Menu.Item className='item-tab' name='arquivos' active={active === 'arquivos'} onClick={handleTabClick}>
                            <Icon name='file' /> Arquivos
                        </Menu.Item>
                        <Menu.Item disabled={globalState.user && !globalState.user.canUpload} className='item-tab' name='upload' active={active === 'upload'} onClick={handleTabClick}>
                            <Icon name='cloud upload' /> Upload
                        </Menu.Item>
                        <Menu.Item disabled={globalState.user && !globalState.user.canUpload} className='item-tab' name='tags' active={active === 'tags'} onClick={handleTabClick}>
                            <Icon name='tags' /> Tags
                        </Menu.Item>
                    </Menu>

                    <Segment attached='bottom'>
                        <h2 className='page-title'>BIBLIOTECA VIRTUAL</h2>
                        {active === 'arquivos' && <Segment className='tab-content'>
                            <SearchForm setDocuments={setDocuments} />
                            <DocumentsResult docs={documents} setDocuments={setDocuments} />
                        </Segment>}
                        {active === 'upload' && <Segment className='tab-content'>
                            <UploadForm />
                        </Segment>}
                        {active === 'tags' && <Segment className='tab-content'>
                            <TagsForm />
                        </Segment>}
                    </Segment>

                </div>
            </div>
        </div>
    )
}
