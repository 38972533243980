import React, { useState, useContext, useReducer } from 'react'
import { GlobalContext } from 'globalContext'
import produce from 'immer'
import http from 'services/http'
import { map } from 'rxjs/operators'
import { Form, Button, Icon } from 'semantic-ui-react'
import { toast } from 'react-toastify'

function reducer(state, action) {
    switch (action.type) {
        case 'setForm':
            return { ...state, ...action.payload }
        default:
            return state
    }
}

export default function UploadForm() {

    const [globalState, dispatchGlobal] = useContext(GlobalContext)
    const [isLoading, setIsLoading] = useState(false)
    const [state, dispatch] = useReducer(reducer, {
        titulo: '',
        descricao: '',
        arquivo: '',
        tags: []
    })

    const addTag$ = value => http.request({ url: '/tags', method: 'POST', body: { tag: value } }).pipe(
        map(({ response }) => response )
    )

    const submit$ = form => http.request({ url: '/documents/upload', method: 'POST', body: form }).pipe(
        map(({ response }) => response)
    )

    function handleChange(event, { name, value }) {
        if (name === 'tags') {
            value = value.pop()
            if (globalState.tags && globalState.tags.filter(x => x.name === value).length === 0)
                return
            else {
                if (state.tags.includes(value)) {
                    value = produce(state.tags, draft => {
                        let i = draft.findIndex(x => x === value)
                        draft.splice(i, 1)
                    })
                } else {
                    value = produce(state.tags, draft => {
                        draft.push(value)
                    })
                }
            }
        }
        if (name === 'arquivo')
            value = event.target.files[0]
        dispatch({ type: 'setForm', payload: { [name]: value } })
    }

    function handleAddition(_, { value }) {
        addTag$(value).subscribe(response => {
            if (!response || response.error) {
                if (response && 'message' in response)
                    toast.error(response.message)
                else
                    toast.error('Ops, ocorreu um erro :(')
            } else {
                const tags = produce(globalState.tags, draft => {
                    draft.push(response)
                })
                value = produce(state.tags, draft => {
                    draft.push(value)
                })
                dispatchGlobal({ type: 'setTags', payload: tags })
                dispatch({ type: 'setForm', payload: { tags: value } })
            }
        })
    }

    function handleSubmit() {
        if (!(!!state.titulo) || !(!!state.descricao) || !(!!state.arquivo) || !(!!state.tags))
            toast.info('Por favor, preencha o formulário antes de enviar!')
        else {
            const formData = new FormData()
            for (const key of Object.keys(state)) {
                if (key === 'arquivo')
                    formData.append(key, state[key], state[key].name)
                else if (Array.isArray(state[key])) {
                    for (const value of state[key]) {
                        formData.append(`${key}[]`, value)
                    }
                } else
                    formData.append(key, state[key])
            }
            
            setIsLoading(true)
            submit$(formData).subscribe(response => {
                setIsLoading(false)
                if (!response || response.error) {
                    if (response && 'message' in response)
                        toast.error(response.message)
                    else
                        toast.error('Ops, ocorreu um erro :(')
                } else {
                    toast.success('Upload realizado com sucesso!')
                }
            })
        }
    }

    return (
        <Form size='small' onSubmit={handleSubmit} noValidate>
            <div className="row pt-3">
                <div className="col-12 col-md-4 offset-md-1 col-lg-3 offset-lg-2 pt-1">
                    <Form.Input required label='Titulo' placeholder='Informe um titulo' name='titulo' value={state.titulo} onChange={handleChange} autoComplete='off' />
                </div>
                <div className="col-12 col-md-6 col-lg-5 pt-1">
                    <Form.Input required label='Descrição' placeholder='Informe uma descrição' name='descricao' value={state.descricao} onChange={handleChange} autoComplete='off' />
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-12 col-md-4 offset-md-1 col-lg-4 offset-lg-2 pt-1">
                    <Form.Input type='file' accept='.pdf,.odt,.odf,.ods,.doc,.docx,.xls,.xlsx' required label='Arquivo' name='arquivo' onChange={handleChange} />
                </div>
                <div className="col-12 col-md-6 col-lg-4 pt-1">
                    <Form.Select required multiple allowAdditions additionLabel='Adicionar: ' search noResultsMessage='Nenhum resultado' label='Tags' placeholder='Adicionar tags' name='tags' value={state.tags} onAddItem={handleAddition} onChange={handleChange} options={globalState.tags ? globalState.tags.map((x, i) => Object.assign({}, { key: i, text: x.name, value: x.name })) : []} />
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-12 col-md-4 offset-md-1 col-lg-3 offset-lg-2">
                    <Button animated='fade' color='green' loading={isLoading} disabled={isLoading}>
                        <Button.Content visible>Enviar</Button.Content>
                        <Button.Content hidden><Icon name='send' /></Button.Content>
                    </Button>
                </div>
            </div>
        </Form>
    )

}
