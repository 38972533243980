import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AcervoPage from './AcervoPage'

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/acervo" component={AcervoPage} />
        </Switch>
    )
}
