import React from 'react'
import { Image } from 'semantic-ui-react'
import logo from 'assets/images/RDP.png'
import './HomePage.scss'

export default function HomePage() {
    return (
        <div id="home">

            <h2 className='mt-0'>BIBLIOTECA VIRTUAL</h2>
            <Image className='logo' size='small' rounded src={logo} />

        </div>
    )
}
