import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Template from 'components/Template'
import SessaoExpiradaPage from 'modules/SessaoExpiradaPage'
import NotFoundPage from 'modules/NotFoundPage'
import ForbiddenPage from 'modules/ForbiddenPage'
import HomePage from 'modules/HomePage'
import AcervoRoutes from 'modules/acervo/Routes'

export default function Routes() {
    return (
        <Template>
            <Switch>
                <Route exact path="/" component={HomePage}></Route>
                <Route exact path="/acervo" component={AcervoRoutes}></Route>
                <Route exact path="/sessao-expirada" component={SessaoExpiradaPage} />
                <Route exact path="/nao-autorizado" component={ForbiddenPage} />
                <Route path="*" component={NotFoundPage} />
            </Switch>
        </Template>
    )
}
