import React from 'react'
import { Image } from 'semantic-ui-react'
import { AutoSizer } from 'react-virtualized'
import { Scrollbars } from 'react-custom-scrollbars'
import logo from 'assets/images/RDP.png'
import './ForbiddenPage.scss'

export default function ForbiddenPage() {
    return (
        <div id="not-found">
            <AutoSizer>
                {({ width, height }) => (
                    <Scrollbars style={{ width, height }}>
                        <h2 className='mt-0'>403 - Não autorizado</h2>            
                        <Image className='logo' size='small' rounded src={logo} />
                   </Scrollbars>
                )}
            </AutoSizer>
        </div>
    )
}
